// Icons
import ForgotPassword from 'pages/ForgotPassword';
// Pages
import Login from 'pages/Login';
import { lazy } from 'react';

// Logged Pages
const Dashboard = lazy(() => import('pages/Dashboard'));
const Eventos = lazy(() => import('pages/Eventos'));
const FiltrarEventos = lazy(() => import('pages/FiltrarEventos'));
const Evento = lazy(() => import('pages/Evento'));
const Clientes = lazy(() => import('pages/Clientes'));
const Fornecedores = lazy(() => import('pages/Fornecedores'));
const CategoriasFornecedores = lazy(() =>
  import('pages/CategoriasFornecedores'),
);
const Funcionarios = lazy(() => import('pages/Funcionarios'));
const ContasFixas = lazy(() => import('pages/ContasFixas'));
const FiltrarTotiNumeros = lazy(() => import('pages/FiltrarTotiNumeros'));
const TotiNumeros = lazy(() => import('pages/TotiNumeros'));

const config = {
  public: [
    {
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      path: '/esqueci-minha-senha',
      component: ForgotPassword,
      exact: true,
    },
  ],
  protected: [
    {
      path: '/',
      component: Dashboard,
      title: 'Início',
      exact: true,
    },
    {
      path: '/categorias-fornecedores',
      component: CategoriasFornecedores,
      title: 'Categorias de Fornecedores',
      exact: true,
      admin: true,
      contador: false,
    },
    {
      path: '/clientes',
      component: Clientes,
      title: 'Clientes',
      exact: true,
      admin: true,
      contador: false,
    },
    {
      path: '/contas-fixas',
      component: ContasFixas,
      title: 'Contas Fixas',
      exact: true,
      admin: true,
      contador: true,
    },
    {
      path: '/filtrar-eventos',
      component: FiltrarEventos,
      title: 'Eventos',
      exact: true,
    },
    {
      path: '/eventos/:ano',
      component: Eventos,
      title: 'Eventos',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/evento/:id',
      component: Evento,
      title: 'Evento',
      exact: true,
      hideFromMenu: true,
    },
    {
      path: '/fornecedores',
      component: Fornecedores,
      title: 'Fornecedores',
      exact: true,
    },
    {
      path: '/funcionarios',
      component: Funcionarios,
      title: 'Funcionários',
      exact: true,
    },
    {
      path: '/filtrar-toti-numeros',
      component: FiltrarTotiNumeros,
      title: 'Toti em Números',
      hideFromMenu: true,
      exact: true,
    },
    {
      path: '/toti-numeros/:ano',
      component: TotiNumeros,
      title: 'Toti em Números',
      hideFromMenu: true,
      exact: true,
    },
  ],
};

export default config;
